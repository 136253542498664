<template>
  <div id="login">
    <el-form>
      <li class="login-way">
        <span :class="isActive && 'way-active'" @click="isActive = true"
          >密码登录</span
        >
        <i class="mid-line"></i>
        <span :class="!isActive && 'way-active'" @click="isActive = false"
          >验证码登录</span
        >
      </li>
      <!-- 账户 -->
      <el-input
        v-model="form.userPhone"
        placeholder="请输入手机号"
        prefix-icon="el-icon-phone"
        clearable
      ></el-input>
      <!-- 密码 -->
      <el-input
        v-if="isActive"
        v-model="form.userPassword"
        placeholder="请输入密码"
        show-password
        prefix-icon="el-icon-lock"
        @keydown.enter="login()"
      ></el-input>
      <!-- 验证码 -->
      <el-input
        v-if="!isActive"
        v-model="securitycode"
        placeholder="请输入验证码"
        prefix-icon="el-icon-message"
      >
        <template #append>
          <span class="securitycode" @click="setSecuritycode()"
            >发送验证码</span
          >
        </template>
      </el-input>
      <!-- 服务商 -->
      <el-select v-model="spid" placeholder="请选择服务商" style="width: 100%">
        <template #prefix>
          <i class="el-input__icon el-icon-office-building"></i>
        </template>
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" style="width: 100%" @click="login()"
        >登 录</el-button
      >
    </el-form>
    <div class="reg" @click="linkReg">立即注册</div>
  </div>
</template>

<script>
import { userLogin } from "@/api/setting/userlist";
export default {
  data() {
    return {
      form: {
        userPhone: "", //账户
        userPassword: "", //密码
      },
      isActive: true, //登录方式
      spid: "1", //默认环境
      options: [
        {
          value: "1",
          label: "正式环境",
        },
        {
          value: "2",
          label: "测试环境",
        },
      ],
    };
  },
  methods: {
    // 登录
    login: _.throttle(function () {
      let data = this.form;
      userLogin(data).then((res) => {
        if (res.success) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.$router.push({
            path: "/home/todolist",
          });
          window.sessionStorage.setItem('yu_token', res.token)
        } else {
          this.$message.error(res.msg);
        }
      });
    }, 1000),
    // 短信验证
    setSecuritycode: _.throttle(function () {
      this.$message({
        message: "发送成功",
        type: "success",
      });
    }, 1000),
    // 跳转注册
    linkReg: _.throttle(function () {
      this.$router.push({
        path: "/mine/register",
      });
    }, 1000),
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/theme";
#login {
  width: 3rem;
  height: 3rem;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  margin: auto;
  box-sizing: border-box;
}

.el-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.login-way {
  width: 100%;
  margin-bottom: 0.2rem;
  display: flex;
  justify-content: space-around;
  font-size: 0.2rem;
  font-weight: bold;
}

.login-way > span {
  cursor: pointer;
  color: #999;
}

.way-active {
  color: #000 !important;
}

.mid-line {
  width: 1px;
  background: rgb(219, 219, 219);
}

.securitycode {
  color: #409eff;
  cursor: pointer;
}

.reg {
  margin: 0.1rem 0;
  text-align: end;
  color: #409eff;
  cursor: pointer;
  font-size: .16rem;
}
</style>